import React, { Component } from 'react';
import './locations-mini.css';

export class LocationsMini extends Component {
    static displayName = LocationsMini.name;

    render() {
        return (
            <div className='locations'>
                <div className='locations-header'>
                    <h1>STORE LOCATIONS</h1>
                </div>
                <div className='locations-info'>
                    <div className={'location-row'}>
                        <div className='location'>
                            <div className='location-city'>
                                Nashville
                            </div>
                            <div className='location-details'>
                                <div className='location-photo'>
                                    <img alt='Nashville Location' src='/img/nashville.png' />
                                </div>
                                <div className='location-address'>
                                    <span>
                                        5320 Nolensville Pk Suite B<br />
                                        Nashville, TN 37211<br />
                                        <a href='tel:615-730-9511'>615.730.9511</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='location'>
                            <div className='location-city'>
                                Franklin
                            </div>
                            <div className='location-details'>
                                <div className='location-photo'>
                                    <img alt='Franklin Location' src='/img/franklin.png' />
                                </div>
                                <div className='location-address'>
                                    <span>
                                        102 Lumber Dr<br />
                                        Franklin, TN  37064<br />
                                        <a href='tel:615-236-9920'>615.236.9920</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'location-row'}>
                    <div className='location'>
                        <div className='location-city'>
                            Murfreesboro
                        </div>
                        <div className='location-details'>
                            <div className='location-photo'>
                                <img alt='Franklin Road Location' src='/img/murph_franklinrd.png' />
                            </div>
                            <div className='location-address'>
                                <span>
                                    3284 Franklin Rd<br />
                                    Murfreesboro, TN  37128<br />
                                    <a href='tel:629-201-8008'>629.201.8008</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={'location'}>
                        <div className='location-city'>
                            Murfreesboro
                        </div>
                        <div className='location-details'>
                            <div className='location-photo'>
                                <img alt='Lascassas Location' src='/img/murph_lascassas.png' />
                            </div>
                            <div className='location-address'>
                                <span>
                                    2089 Lascassas Pike Suite C<br />
                                    Murfreesboro, TN  37130<br />
                                    <a href='tel:615-603-7762'>615.603.7762</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className={'location-row'}>
                        <div className='location'>
                            <div className='location-city'>
                                Smyrna
                            </div>
                            <div className='location-details'>
                                <div className='location-photo'>
                                    <img alt='Smyrna Location' src='/img/smyrna.png' />
                                </div>
                                <div className='location-address'>
                                <span>
                                    504 Enon Springs Rd Suite E<br />
                                    Smyrna, Tn  37167<br />
                                    <a href='tel:615-267-0412'>615.267.0412</a>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
