import { Home } from "./components/home/home";
import { LocationsMini } from "./components/locations/locations-mini";
import { AboutUs } from "./components/about-us/about-us";
import { Products } from "./components/products/products";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/locations',
        element: <LocationsMini />
    },
    {
        path: '/products',
        element: <Products />
    },
    {
        path: '/about-us',
        element: <AboutUs />
    },
    //{
    //    path: '/contact-us',
    //    element: <ContactUs />
    //}
];

export default AppRoutes;
